import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { Spacing } from '@bscom/components/Spacing';
import { desktops } from '@bscom/styling/breakpoints';
import { MAX_PAGE_WIDTH } from '@bscom/constants/widths';
import { BasicDropDown } from '@bscom/components/DropDown';

import { Search } from 'components/SearchBar';
import { MobileMenu } from '@bscom/components/MobileMenu';
import { countrySlugToNames } from 'constants/countries';
import { categorySlugToNames } from 'constants/categories';

const FilterBarWrapper = styled.div`
  background: white;
  display: none;

  @media (${desktops}) {
    display: block;
  }
`;

const FilterBar = styled(Spacing)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const FilterBarInner = styled.div`
  row-gap: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (${desktops}) {
    flex-direction: row;
    padding: 0;
  }
`;

const FilterLabel = styled.span`
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
`;

const SpacedDropdown = styled(BasicDropDown)`
  margin: 0 10px;
  width: 100%;
  min-width: 256px;
  display: block;
`;

const SlugToNameFilter = ({ slugsAndNames, currentSlug, ...dropdownProps }) => {
  const categoryMenuItems = Object.entries(slugsAndNames).map(
    ([slug, catName]) => ({ value: slug, label: catName })
  );

  const initialValue = categoryMenuItems.find(
    (menuItem) => menuItem.value === currentSlug
  )?.value;

  return (
    <SpacedDropdown
      initialValue={initialValue}
      menuItems={categoryMenuItems}
      {...dropdownProps}
    />
  );
};

export const EntriesFilterBar = () => {
  const router = useRouter();

  const goToCategoryPage = ({ value }) => router.push(`/category/${value}`);
  const goToCountryPage = ({ value }) => router.push(`/country/${value}`);

  const filterMenuJsx = (
    <FilterBarInner>
      <SlugToNameFilter
        onChange={goToCategoryPage}
        currentSlug={router.query.slug}
        placeholder="Filter by category "
        slugsAndNames={categorySlugToNames}
      />
      <SlugToNameFilter
        onChange={goToCountryPage}
        currentSlug={router.query.slug}
        placeholder="Filter by country "
        slugsAndNames={countrySlugToNames}
      />
      <Search />
    </FilterBarInner>
  );

  return (
    <>
      <MobileMenu renderMenu={() => filterMenuJsx} currentItem="Filter" />
      <FilterBarWrapper>
        <FilterBar
          withXPadding
          spaces={[{ top: 20, bottom: 20 }]}
          contentWidth={MAX_PAGE_WIDTH}
        >
          <FilterLabel>Filter:</FilterLabel>
          {filterMenuJsx}
        </FilterBar>
      </FilterBarWrapper>
    </>
  );
};
