import getConfig from 'next/config';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslations } from 'ilenia';

import { GREY_LIGHTER } from '@bscom/constants/colors';
import { BusinessFooter } from '@bscom/components/Footer/BusinessFooter';

import { DARK_GREEN } from "constants/colours";
import { NavigationPress } from 'components/NavigationPress';
import Head from 'next/head';

const {
  publicRuntimeConfig: { baseUrl },
} = getConfig();

const PageWrapper = styled.div`
  ${({ bgColor }) => `
    position: relative;
    background-color: ${bgColor || GREY_LIGHTER};
  `}
`;

const StyledContainer = styled.div`
  footer {
    background: ${DARK_GREEN};
  }
`

export const BaseLayout = ({ children, bgColor }) => {
  const { asPath } = useRouter();
  const [, localeCode] = useTranslations();

  return (
    <PageWrapper bgColor={bgColor}>
      <Head>
        <link
          rel="canonical"
          href={`https://${baseUrl}${asPath.split('?')[0] || ''}`}
        />
      </Head>
      <NavigationPress localeCode={localeCode} />
      <main id="main">{children}</main>
      <StyledContainer>
      <BusinessFooter
        {...{
          baseUrl,
          asPath,
          localeCode,
          filter: 'business',
        }}
      />
      </StyledContainer>
    </PageWrapper>
  );
};
