import Router from 'next/router';
import { Search as BscomSearch } from '@bscom/components/Search';

import { fetchData } from 'lib/cfulClient';

const trackSearchAttempted = (query) => {
  window?.analytics?.track('Search Attempted', {
    category: 'article',
    label: query,
  });
};

const trackSearchSubmitted = (query, category, link) => {
  window?.analytics?.track('Search Submitted', {
    category,
    label: query,
    link,
  });
};

export const Search = () => {
  const handleSearch = async (query) => {
    const results = await fetchData({
      contentType: 'page',
      queries: {
        'fields.content.sys.contentType.sys.id': 'pressEntry',
        'fields.content.fields.name[match]': query,
      },
    });

    if (query.length > 3) {
      trackSearchAttempted(query);
    }

    return results?.itemsCollection?.items || [];
  };

  const handleItemSelect = (query, entry) => {
    let url =
      entry.content.content.sys.contentType.sys.id === 'internalArticle'
        ? `/${entry.slug}`
        : entry.content.content.url;

    Router.push(url);

    trackSearchSubmitted(query, url, url);
  };

  return (
    <BscomSearch onChange={handleSearch} onItemSelect={handleItemSelect} />
  );
};
