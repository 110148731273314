export const countrySlugToNames = {
  australia: 'Australia',
  denmark: 'Denmark',
  france: 'France',
  germany: 'Germany',
  global: 'Global',
  italy: 'Italy',
  netherlands: 'Netherlands',
  'united-kingdom': 'United Kingdom',
  'united-states': 'United States',
};

export const getCountrySlug = (country) => {
  const countryEntry = Object.entries(countrySlugToNames).find(
    ([, countryName]) => countryName === country
  );

  return countryEntry ? countryEntry[0] : 'country-not-found';
};
