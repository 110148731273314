import React from 'react';
import { useTranslations } from 'ilenia';
import styled from 'styled-components';

import { DARK_GREEN } from 'constants/colours'

import {
  LinkWrapper,
  ButtonWrapper,
} from '@bscom/components/Navigation/NavigationLinks';
import NavigationBasic from '@bscom/components/Navigation/NavigationBasic';

const StyledNavBasic = styled(NavigationBasic)`
  background: ${DARK_GREEN};
   div[class*="NavigationBasic__HeaderLinks"] {
    background: ${DARK_GREEN};
  }
`

export const NavigationPress = () => {
  const [translations] = useTranslations();

  return (
    <StyledNavBasic logoHref="/">
      <LinkWrapper
        translations={translations['header/news']}
        identifier="how-reviews-work"
        href="/"
      />
      <LinkWrapper
        translations={translations['header/downloadassets']}
        identifier="our-business-model"
        href="/download-assets"
      />
      <LinkWrapper
        translations={translations['header/contact']}
        identifier="our-business-model"
        href="/press-contacts"
      />

      <ButtonWrapper
        translations={translations['header/forbusiness']}
        identifier="contact"
        href="https://business.trustpilot.com/?utm_medium=press&utm_source=for_companies&utm_campaign=press_cta"
        styling="solid"
      />
    </StyledNavBasic>
  );
};

export default NavigationPress;
