import styled from 'styled-components';

import { tablets, desktops } from '@bscom/styling/breakpoints';

export const Grid = styled.div`
  ${({ noOfColumns }) => `
    display:grid;
    grid-gap: 20px;
    max-width: 100%;
    grid-template-columns: 1fr;

    @media(${tablets}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media(${desktops}) {
      grid-template-columns: repeat(${noOfColumns}, 1fr);
    }
  `}
`;
