export const categorySlugToNames = {
  'trustpilot-announcements': 'Trustpilot announcements',
};

export const getCategorySlug = (category) => {
  const slugEntry = Object.entries(categorySlugToNames).find(
    ([, categoryName]) => categoryName === category
  );

  return slugEntry ? slugEntry[0] : 'category-not-found';
};
