import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Link } from '@bscom/components/Links';
import { H2, H4 } from '@bscom/components/Headers';
import { ContentfulMediaImage } from '@bscom/components/Image';
import { desktops } from '@bscom/styling/breakpoints';

import { getCategorySlug } from 'constants/categories';

const FeaturedEntryWrapper = styled.div`
  display: grid;
  margin: 20px 0;
  flex-direction: column;
  background-color: white;
  color: ${({ theme }) => theme.colors.bluePrimary};

  @media (${desktops}) {
    margin: 40px 0;
    grid-template-columns: 56.5fr 43.5fr;
  }
`;

const FeaturedEntryContent = styled.div`
  padding: 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media (${desktops}) {
    padding: 50px;
  }
`;

const PressEntryDetails = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    margin-top: auto;
    flex-direction: row;
    color: ${theme.colors.greyDarker};
    font-size: ${theme.fontSizes.xs};
    
    span {
      display: inline-block;
      margin-right: 28px;
    }
  `}
`;

const PressEntryContainer = styled.div`
  height: 100%;
  display: flex;
  background: white;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.bluePrimary};
`;

const PressEntrySpacing = styled.div`
  ${({ withBottomAuto }) => `
    padding: 30px;
    ${withBottomAuto ? 'margin-bottom: auto;' : ''}
  `}
`;

const EntryPreviewHeader = styled(H4)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const EntryCategory = styled.a`
  ${({ theme }) => `
    margin-bottom: 12px;
    display: inline-block;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.bluePrimary};
  `}
`;

const ArrowIcon = styled.img`
  width: 15px;
  margin-left: auto;
`;

const FeaturedPressEntryHeader = styled(H2)`
  margin-bottom: 20px;
`;

const EntryCategoryLink = ({ categorySlug, category }) => {
  const [showLink, setShowLink] = useState(false);

  useEffect(() => setShowLink(true), []);

  return showLink ? (
    <EntryCategory href="/category/[slug]" hrefAs={`/category/${categorySlug}`}>
      {category}
    </EntryCategory>
  ) : null;
};

export const FeaturedPressEntryPreview = ({ entry }) => {
  const entryContent = entry.content;
  const isExternal =
    entryContent.content.sys.contentType.sys.id === 'externalArticle';

  const categorySlug = getCategorySlug(entryContent.category);

  return (
    <Link
      href={isExternal ? entryContent.content.url : `/${entry.slug}`}
      target={isExternal ? '_blank' : undefined}
    >
      <FeaturedEntryWrapper>
        <ContentfulMediaImage
          priority
          image={entryContent.previewImage}
          ratio="16/9"
        />
        <FeaturedEntryContent>
          <EntryCategoryLink
            categorySlug={categorySlug}
            category={entryContent.category}
          />

          <FeaturedPressEntryHeader>{entry.title}</FeaturedPressEntryHeader>
          <PressEntryDetails>
            <span>
              {new Date(entry.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
            <span>{entryContent.location}</span>
            <ArrowIcon
              src={isExternal ? '/external-arrow.svg' : '/arrow.svg'}
              alt=""
            />
          </PressEntryDetails>
        </FeaturedEntryContent>
      </FeaturedEntryWrapper>
    </Link>
  );
};

export const PressEntryPreview = ({ entry }) => {
  const entryContent = entry.content;
  const isExternal =
    entryContent.content.sys.contentType.sys.id === 'externalArticle';

  const categorySlug = getCategorySlug(entryContent.category);

  return (
    <Link
      href={isExternal ? entryContent.content.url : `/${entry.slug}`}
      target={isExternal ? '_blank' : undefined}
    >
      <PressEntryContainer>
        <PressEntrySpacing withBottomAuto>
          <EntryCategoryLink
            categorySlug={categorySlug}
            category={entryContent.category}
          />
          <EntryPreviewHeader>{entry.title}</EntryPreviewHeader>
        </PressEntrySpacing>
        <ContentfulMediaImage
          image={entryContent.previewImage}
          sizes="25vw"
          ratio="16/9"
        />
        <PressEntrySpacing>
          <PressEntryDetails>
            <span>
              {new Date(entry.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
            <span>{entryContent.location}</span>
            <ArrowIcon
              alt="arrow icon"
              src={isExternal ? '/external-arrow.svg' : '/arrow.svg'}
            />
          </PressEntryDetails>
        </PressEntrySpacing>
      </PressEntryContainer>
    </Link>
  );
};
