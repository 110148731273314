import getConfig from 'next/config';
import { restMapper } from '@bscom/utilities/restMapper';

const contentful = require('contentful');

const {
  publicRuntimeConfig: { contentful: contentfulConfig },
} = getConfig();

const cfulClient = contentful.createClient({
  resolveLinks: true,
  removeUnresolved: true,
  ...contentfulConfig,
});

export const fetchDataREST = async ({
  contentType,
  queries = {},
  localeCode,
  limit = 1000,
}) => {
  return await cfulClient.getEntries({
    content_type: contentType,
    locale: localeCode,
    ...queries,
    include: 10,
    limit: limit,
  });
};

export const fetchData = async (props) => {
  const restData = await fetchDataREST(props);

  return restMapper(restData);
};
